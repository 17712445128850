/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.App {
  text-align: center;
  width: 100%;
  overflow-x: hidden; }

.App-logo {
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.625rem + 2vmin);
  color: #ffffff; }

.App-link {
  color: #09d3ac; }

.subTitle {
  color: #868e96; }
