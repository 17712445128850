/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.talk-header-title {
  font-size: 56px;
  font-weight: 400;
  line-height: 0px; }

.talk-cards-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  gap: 1rem 1rem; }

.subTitle {
  color: #868e96; }

@media (max-width: 768px) {
  .talk-header-title {
    font-size: 30px;
    text-align: center; }
  .talk-header-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center; } }
