/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.podcast-header-title {
  font-size: 56px;
  font-weight: 400;
  line-height: 0px; }

.podcast {
  width: 600px; }

.podcast-main-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem 1rem; }

.subTitle {
  color: #868e96; }

@media (max-width: 768px) {
  .podcast-header-title {
    font-size: 30px;
    text-align: center; }
  .podcast-header-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center; }
  .podcast {
    width: 300px; }
  .podcast-main-div {
    text-align: center; } }
