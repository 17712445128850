/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.achievement-heading {
  font-size: 56px;
  font-weight: 400;
  line-height: normal;
  margin: 0px; }

.achievement-subtitle {
  text-transform: uppercase;
  margin: 0px;
  margin-bottom: 50px; }

.subTitle {
  color: #868e96; }

/* Media Query */
@media (max-width: 1380px) {
  .achievement-heading {
    font-size: 40px; } }

@media (max-width: 768px) {
  .achievement-heading {
    font-size: 30px;
    text-align: center; }
  .achievement-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center; } }
