/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center; }

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px; }

.software-skill-inline > i {
  color: #868e96; }

.software-skill-inline > i:hover {
  color: #645beb; }

.software-skill-inline > p {
  color: #868e96;
  font-size: 10px; }

.software-skill-inline > i:hover ~ p {
  color: #645beb; }
