@font-face {
  font-family: "Agustina Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Agustina Regular"),
    url(/static/media/Agustina.21f233e1.woff) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(/static/media/Montserrat-Regular.ee653992.ttf) format("woff");
  font-display: swap;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font: 19px / 23px Montserrat, "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Media Query */
@media (max-width: 1380px) {
  html,
  body {
    font-size: rem;
    line-height: normal;
  }
}

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.App {
  text-align: center;
  width: 100%;
  overflow-x: hidden; }

.App-logo {
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.625rem + 2vmin);
  color: #ffffff; }

.App-link {
  color: #09d3ac; }

.subTitle {
  color: #868e96; }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
/*React-headroom*/
.headroom-wrapper {
  max-height: 100px; }

/* header */
.dark-menu {
  background-color: #171c28 !important;
  color: #ffffff; }

.dark-menu li a {
  color: #ffffff !important; }

.dark-menu li a:hover {
  background-color: #55198b !important; }

.header {
  background-color: #fff;
  max-width: 100%;
  padding: 15px 20px;
  margin: 0px auto; }

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden; }

.grey-color {
  color: #868e96; }

.header li a {
  display: block;
  padding: 15px 20px;
  color: #000000;
  text-decoration: none; }

.header li a:hover,
.header .menu-btn:hover {
  background-color: #f4f4f4; }

.header .logo {
  display: block;
  flex: none;
  float: left;
  font-size: 1.5em;
  text-decoration: none;
  margin-top: 10px;
  line-height: normal; }

.header .logo-name {
  font-family: "Agustina Regular";
  font-weight: bold;
  font-feature-settings: "liga", "clig off";
  font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
  padding: 0 10px; }

/* menu */
.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out; }

/* menu icon */
.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  -webkit-user-select: none;
          user-select: none; }

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px; }

.header .menu-icon .navicon-dark {
  background: #ffffff; }

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%; }

.header .menu-icon .navicon-dark:before,
.header .menu-icon .navicon-dark:after {
  background: #ffffff !important;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%; }

.header .menu-icon .navicon:before {
  top: 5px; }

.header .menu-icon .navicon:after {
  top: -5px; }

/* menu btn */
.header .menu-btn {
  display: none; }

.header .menu-btn:checked ~ .menu {
  max-height: 486px; }

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent !important; }

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg); }

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg); }

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0; }

/* 48em = 768px */
@media (min-width: 48em) {
  .header li {
    float: left; }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .header .menu {
    max-height: none; }
  .header .menu-icon {
    display: none; } }

@media (max-width: 960px) and (min-width: 768px) {
  .header {
    font-size: 12px; } }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px; }

/* Fix visible slider checkbox */
input {
  transform: scale(0.5); }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s; }

.slider::before {
  position: absolute;
  content: "";
  height: 26px;
  width: 28px;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
  transition: 0.4s; }

input:checked + .slider {
  background-color: #2196f3; }

input:focus + .slider {
  box-shadow: #2196f3; }

input:checked + .slider::before,
input:checked + .slider > .emoji {
  transform: translateX(26px); }

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  display: flex;
  align-items: center; }

.slider.round::before {
  border-radius: 50%;
  display: grid;
  place-items: center; }

.slider::after {
  display: inline;
  position: absolute;
  right: 9.3%;
  bottom: 17.5%; }

input:checked + .slider::after {
  display: inline;
  position: absolute;
  right: 56.3%;
  bottom: 14.5%; }

.emoji {
  z-index: 10;
  display: flex;
  align-items: center;
  margin-left: 2px; }

@media all and (max-width: 786px) and (min-width: 425px) {
  input:checked + .slider::after {
    right: 60.3%;
    bottom: 20.5%; }
  .slider::after {
    right: 15.3%;
    bottom: 22.5%; } }

@media all and (max-width: 2560px) and (min-width: 1552px) {
  .slider::after {
    bottom: 12.5% !important; } }

@media all and (max-width: 1552px) and (min-width: 1440px) {
  .slider::after {
    bottom: 7.5% !important; } }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 4rem; }

.greet-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem; }

.greeting-main {
  display: flex; }

.subTitle {
  color: #868e96 !important; }

.greeting-main {
  display: flex; }

.greeting-main > * {
  flex: 1 1;
  margin-bottom: 30px; }

.button-greeting-div {
  display: flex;
  margin-top: 20px; }

.greeting-text {
  font-size: 70px;
  line-height: 1.1;
  color: #000000 !important; }

.greeting-text-p {
  font-size: 30px;
  line-height: 40px; }

.greeting-image-div > img {
  max-width: 100%;
  height: auto; }

.wave-emoji {
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-name: wave;
  display: inline-block;
  transform-origin: 70% 70%; }

@keyframes wave {
  0% {
    transform: rotate(0deg); }
  10% {
    transform: rotate(-10deg); }
  20% {
    transform: rotate(12deg); }
  30% {
    transform: rotate(-10deg); }
  40% {
    transform: rotate(9deg); }
  50% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(0deg); } }

/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px; }
  .greeting-text-p {
    font-size: 20px; } }

@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around; }
  .greeting-text {
    font-size: 30px;
    text-align: center; }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
    text-align: center; }
  .greeting-main {
    display: block; }
  .greeting-image-div {
    height: 256px; } }

@media (max-width: 320px) {
  .main {
    width: auto; } }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.sk-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative; }

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both; }

.sk-circle .sk-circle2 {
  transform: rotate(30deg); }

.sk-circle .sk-circle3 {
  transform: rotate(60deg); }

.sk-circle .sk-circle4 {
  transform: rotate(90deg); }

.sk-circle .sk-circle5 {
  transform: rotate(120deg); }

.sk-circle .sk-circle6 {
  transform: rotate(150deg); }

.sk-circle .sk-circle7 {
  transform: rotate(180deg); }

.sk-circle .sk-circle8 {
  transform: rotate(210deg); }

.sk-circle .sk-circle9 {
  transform: rotate(240deg); }

.sk-circle .sk-circle10 {
  transform: rotate(270deg); }

.sk-circle .sk-circle11 {
  transform: rotate(300deg); }

.sk-circle .sk-circle12 {
  transform: rotate(330deg); }

.sk-circle .sk-circle2:before {
  animation-delay: -1.1s; }

.sk-circle .sk-circle3:before {
  animation-delay: -1s; }

.sk-circle .sk-circle4:before {
  animation-delay: -0.9s; }

.sk-circle .sk-circle5:before {
  animation-delay: -0.8s; }

.sk-circle .sk-circle6:before {
  animation-delay: -0.7s; }

.sk-circle .sk-circle7:before {
  animation-delay: -0.6s; }

.sk-circle .sk-circle8:before {
  animation-delay: -0.5s; }

.sk-circle .sk-circle9:before {
  animation-delay: -0.4s; }

.sk-circle .sk-circle10:before {
  animation-delay: -0.3s; }

.sk-circle .sk-circle11:before {
  animation-delay: -0.2s; }

.sk-circle .sk-circle12:before {
  animation-delay: -0.1s; }

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.social-media-div {
  font-size: 2em; }

.icon-button {
  margin-bottom: 10px; }

.icon-button i {
  color: #ffffff;
  border-radius: 2.6rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1.3rem;
  height: 2.6rem;
  line-height: 2.6rem;
  margin: 0 5px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  width: 2.6rem;
  margin-bottom: 10px;
  transition: 0.2s ease-in; }

.facebook i {
  background-color: #3b5998; }

.linkedin i {
  background-color: #0e76a8; }

.github i {
  background-color: #333; }

.gitlab i {
  background-color: #fca326; }

.google i {
  background-color: #ea4335; }

.twitter i {
  background-color: #1da1f2; }

.medium i {
  background-color: #000; }

.stack-overflow i {
  background-color: #f48024; }

.kaggle i {
  background-color: #20beff; }

.phone i {
  background-color: #1243e0; }

.instagram i {
  background-color: #c13584; }

/* Hover Transition */
.twitter i:hover,
.google i:hover,
.gitlab i:hover,
.github i:hover,
.linkedin i:hover,
.facebook i:hover,
.instagram i:hover,
.stack-overflow i:hover,
.kaggle i:hover,
.medium i:hover {
  background-color: #000000; }

/* Media Query */
@media (max-width: 768px) {
  .social-media-div {
    text-align: center; } }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.main-button {
  background-color: #55198b;
  border: solid 1px #55198b;
  color: #ffffff;
  font-weight: 700;
  width: -webkit-max-content;
  width: max-content;
  padding: 13px 22px;
  margin-right: 50px;
  text-transform: uppercase;
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  display: block;
  margin-top: 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s; }

.main-button:hover {
  background-color: #ffffff;
  color: #55198b;
  transition: all 0.3s ease 0s;
  transform: translateY(-3px); }

.project-button {
  display: flex;
  justify-content: center;
  margin-top: 20px; }

.project-button > .main-button {
  margin-right: 0 !important; }

/* Media Query */
@media (max-width: 768px) {
  .main-button {
    font-size: 0.938rem;
    padding: 12px 18px;
    margin-right: 0px; } }

@media (max-width: 320px) {
  .main-button {
    font-size: 0.75rem; } }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.skills-main-div {
  display: flex;
  width: 100%;
  overflow: hidden; }

.skills-text-div {
  margin-left: 50px; }

.skills-main-div > * {
  flex: 1 1;
  margin-bottom: 30px; }

.skills-image-div > img {
  max-width: 100%;
  height: auto; }

.skills-heading {
  font-size: 56px;
  font-weight: 400; }

.subTitle {
  color: #868e96; }

/* Media Query */
@media (max-width: 1380px) {
  .skills-heading {
    font-size: 40px; } }

@media (max-width: 768px) {
  .skills-heading {
    font-size: 30px;
    text-align: center; }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal; }
  .skills-main-div {
    flex-direction: column; }
  .skills-text-div {
    margin-left: 0px;
    margin: 20px; }
  .skills-text {
    font-size: 16px; }
  .skills-text-subtitle {
    font-size: 16px;
    text-align: center; }
  .skills-image-div {
    /* display: none; */
    order: 2; } }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center; }

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px; }

.software-skill-inline > i {
  color: #868e96; }

.software-skill-inline > i:hover {
  color: #645beb; }

.software-skill-inline > p {
  color: #868e96;
  font-size: 10px; }

.software-skill-inline > i:hover ~ p {
  color: #645beb; }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.meter {
  height: 20px;
  position: relative;
  background: #f3efef;
  border-radius: 25px; }

.meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #aaa5ff;
  position: relative;
  overflow: hidden; }

.skill {
  line-height: 3.5vw; }

.skill-bars {
  font-size: 28px;
  width: 40%; }

.skills-container {
  display: flex;
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem; }

.skills-container > * {
  flex: 1 1;
  margin-bottom: 30px; }

.skills-image > img {
  margin-left: 80px;
  max-width: 90%;
  height: auto; }

/* Media Query */
@media (max-width: 1456px) {
  .skills-bar {
    line-height: 4rem; }
  .skills-image {
    display: none;
    order: 2; } }

@media (max-width: 768px) {
  .skills-container {
    flex-direction: column; }
  .skills-bar {
    line-height: 3rem;
    margin: 20px; }
  .skills-image {
    display: none;
    order: 2; } }

.experience-cards-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  grid-gap: 1rem 1rem;
  gap: 1rem 1rem; }

.experience-heading {
  font-size: 56px;
  font-weight: 400; }

.experience-container {
  display: flex;
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem; }

.experience-container > * {
  flex: 1 1; }

/* Media Query */
@media (max-width: 1380px) {
  .experience-heading {
    font-size: 40px; } }

@media (max-width: 768px) {
  .experience-heading {
    font-size: 30px;
    text-align: center; } }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.experience-card {
  position: relative;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  border-radius: 10px;
  border: 1px solid rgba(211, 211, 211, 0.397); }

.experience-card-dark {
  position: relative;
  background-color: #171c28;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  border-radius: 10px;
  border: 1px solid rgba(211, 211, 211, 0.397); }

.experience-card:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px; }

.experience-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 150%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
  border-radius: 10px 10px 0px 0px;
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: blur(0px);
  height: 11rem;
  display: flex;
  align-items: flex-end;
  justify-content: center; }

.experience-blurred_div {
  position: absolute;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2));
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  height: 11rem;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 10px 10px 0px 0px; }

.experience-div-company {
  position: absolute;
  background: transparent;
  height: 9rem;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.experience-text-company {
  text-align: center;
  padding: 1.5rem;
  margin: 0;
  color: #fff;
  font-size: 25px;
  text-align: center;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.experience-roundedimg {
  position: absolute;
  object-fit: cover;
  left: 0;
  right: 0;
  top: 7rem;
  margin-left: auto;
  margin-right: auto;
  width: 8rem;
  height: 8rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  border-radius: 50%; }

.experience-text-role {
  text-align: center;
  color: #000000;
  font-weight: 700;
  font-size: 25px;
  margin: 0px;
  padding-top: 1.5rem;
  line-height: normal; }

.dark-mode-text {
  color: #ffffff !important; }

.experience-text-date {
  text-align: center;
  color: #000000;
  font-size: 20px;
  margin: 0px;
  padding-top: 1rem;
  font-weight: 600; }

.experience-text-desc {
  text-align: center; }

.experience-text-details {
  padding: 1.5rem;
  margin-top: 2rem; }

/* Media Query */
@media (max-width: 1380px) {
  .experience-text-role {
    padding-top: 0.5rem;
    font-size: 22px; }
  .experience-text-company {
    font-size: 22px; }
  .experience-text-date {
    font-size: 18px; }
  .experience-roundedimg {
    width: 6.5rem;
    height: 6.5rem; } }

@media (max-width: 768px) {
  .experience-text-role {
    font-size: 22px; }
  .experience-text-company {
    font-size: 22px; }
  .experience-text-date {
    font-size: 18px; }
  .experience-text-desc {
    font-size: 16px; } }

.project-title {
  font-size: 52px;
  font-weight: 400;
  line-height: normal; }

.startup-project-text img {
  max-width: 100%;
  height: auto; }

.repo-cards-div-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 1rem 1rem;
  gap: 1rem 1rem; }

/* Media Query */
@media (max-width: 1380px) {
  .project-title {
    font-size: 40px; } }

@media (max-width: 768px) {
  .project-title {
    font-size: 30px;
    text-align: center; }
  .repo-cards-div-main {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); } }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.card-image {
  max-width: 100%;
  height: auto;
  width: 250px; }

.card-title {
  color: #000000;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  margin: 15px 0 0 0; }

.card-subtitle {
  color: #666666;
  font-size: 17px;
  line-height: 1.5rem; }

.project-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 1.5rem;
  border-radius: 10px;
  border: 1px solid rgba(211, 211, 211, 0.397);
  justify-content: center; }

.project-card-light {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  transition: all 0.3s ease; }

.project-card-dark {
  box-shadow: 0px 10px 30px -15px #d9dbdf;
  transition: all 0.25s ease; }

.project-detail {
  text-align: center; }

.project-card-light:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px; }

.project-card-dark:hover {
  box-shadow: 0px 20px 30px -10px #d9dbdf; }

.project-image {
  position: relative;
  height: 250px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center; }

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem 1rem;
  gap: 1rem 1rem; }

.project-card-footer span.project-tag {
  background: #55198b;
  color: #f5f2f4;
  vertical-align: middle;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  white-space: nowrap;
  line-height: 1.5;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0 0.75em;
  cursor: pointer;
  transition: 0.2s ease-in; }

@media (max-width: 768px) {
  .project-subtitle {
    font-size: 16px;
    text-align: center; } }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.achievement-heading {
  font-size: 56px;
  font-weight: 400;
  line-height: normal;
  margin: 0px; }

.achievement-subtitle {
  text-transform: uppercase;
  margin: 0px;
  margin-bottom: 50px; }

.subTitle {
  color: #868e96; }

/* Media Query */
@media (max-width: 1380px) {
  .achievement-heading {
    font-size: 40px; } }

@media (max-width: 768px) {
  .achievement-heading {
    font-size: 30px;
    text-align: center; }
  .achievement-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center; } }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.card-image {
  max-width: 100%;
  height: auto;
  width: 250px; }

.card-title {
  color: #000000;
  font-size: 1.375rem;
  line-height: 24px;
  font-weight: 700;
  margin: 15px 0 0 0; }

.card-subtitle {
  color: #666666;
  font-size: 1.063rem;
  line-height: 1.5rem; }

.certificate-card {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  padding: 1.5rem;
  border-radius: 10px;
  border: 1px solid rgba(211, 211, 211, 0.397);
  transition: all 0.3s ease; }

.certificate-detail-div {
  text-align: center; }

.certificate-card:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px; }

.dark-mode.certificate-card:hover {
  box-shadow: rgba(255, 255, 255, 0.2) 0px 20px 30px -10px; }

.certificate-image-div {
  position: relative;
  height: 250px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center; }

.achievement-cards-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem 1rem;
  gap: 1rem 1rem; }

.certificate-card-footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center; }

.certificate-card-footer span.certificate-tag {
  background: #55198b;
  color: #f5f2f4;
  vertical-align: middle;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  white-space: nowrap;
  line-height: 1.5;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0 0.75em;
  cursor: pointer;
  transition: 0.2s ease-in; }

span.certificate-tag:hover {
  background: #8c43ce; }

/*Blog Cards Layout*/
/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.square {
  width: 440px;
  height: 430px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 20px 50px #d9dbdf;
  transition: all 0.3s ease;
  margin-top: 30px; }

.square:hover {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1); }

.blog-header-text {
  font-size: 56px;
  font-weight: 400; }

.blog-subtitle {
  text-transform: uppercase; }

.blog-main-div > * {
  flex: 1 1;
  margin-bottom: 30px; }

.blog-main-div {
  display: flex; }

.blog-image-div > img {
  max-width: 100%;
  height: auto; }

.blog-text-div {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 32px;
  gap: 32px; }

.subTitle {
  color: #868e96; }

/* Media Query */
@media (max-width: 1380px) {
  .blog-header-text {
    font-size: 40px; } }

@media (max-width: 768px) {
  .blog-header-text {
    font-size: 30px;
    text-align: center; }
  .blog-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center; } }

@media screen and (max-width: 480px) and (min-width: 300px) {
  .blog-text-div {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); } }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.small-dark {
  color: #ffffff !important; }

.blog-container {
  border-radius: 10px;
  color: #586069;
  background-color: #fff;
  box-shadow: 0 0 36px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 200px; }

.blog-title {
  color: #262626;
  font-size: 1.375rem;
  line-height: 24px;
  font-weight: 700;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 14px;
  margin-top: 32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.small {
  font-size: 1.063rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #666666;
  padding: 0px 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0px; }

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #55198b;
  border-radius: 0 4px 0 32px; }

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: #ffffff;
  font-family: courier, sans; }

.blog-card {
  display: block;
  position: relative;
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  height: 100%; }

.blog-card::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #55198b;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.4s ease-out; }

.blog-card:hover::before {
  transform: scale(30); }

.blog-card:hover > .small {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8); }

.blog-card:hover > h3 {
  transition: all 0.3s ease-out;
  color: #ffffff; }

@media screen and (max-width: 480px) and (min-width: 300px), (min-width: 925px) and (max-width: 1024px), (min-width: 1405px) and (max-width: 1550px) {
  .blog-card:hover::before {
    transform: scale(30); } }

@media screen and (min-width: 481px) and (max-width: 675px), (min-width: 1025px) and (max-width: 1404px), (min-width: 1551px) {
  .blog-card:hover::before {
    transform: scale(40); }
  .blog-card::before {
    transition: transform 0.45s ease-out; } }

@media screen and (min-width: 675px) and (max-width: 860px) {
  .blog-card:hover::before {
    transform: scale(50); }
  .blog-card::before {
    transition: transform 0.5s ease-out; } }

@media screen and (min-width: 860px) and (max-width: 924px) {
  .blog-card:hover::before {
    transform: scale(60); }
  .blog-card::before {
    transition: transform 0.5s ease-out; } }

@media screen and (max-width: 480px) and (min-width: 300px) {
  .blog-title {
    font-size: 1.125rem; } }

.blog-card-shadow {
  box-shadow: 1px 0px 20px #ffffff; }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.footer-text {
  text-align: center;
  color: #868e96 !important; }

.dark-mode {
  color: #ffffff !important; }

.footer-div {
  margin-top: 2rem;
  padding-bottom: 1rem; }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.talk-header-title {
  font-size: 56px;
  font-weight: 400;
  line-height: 0px; }

.talk-cards-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  grid-gap: 1rem 1rem;
  gap: 1rem 1rem; }

.subTitle {
  color: #868e96; }

@media (max-width: 768px) {
  .talk-header-title {
    font-size: 30px;
    text-align: center; }
  .talk-header-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center; } }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.container {
  display: flex;
  justify-content: center; }

/* Rectangle Card Layout */
.rectangle {
  background: url(/static/media/talksCardBack.13780cdd.svg);
  width: 380px;
  height: 300px;
  border-radius: 4px;
  box-shadow: 0px 20px 50px #d9dbdf;
  transition: all 0.3s ease;
  margin-top: 50px; }

.rectangle:hover {
  box-shadow: 0 20px 40px #ffffff; }

.dark-rectangle {
  box-shadow: 0px 0px 20px 0px; }

.mask {
  clip: rect(0px, 460px, 220px, 0px);
  border-radius: 4px;
  position: absolute; }

/* Card  Styling */
.talk-card-title {
  color: #ffffff;
  margin: auto;
  text-align: left;
  margin-top: 25px;
  padding: 0px 0px 40px 30px;
  font-size: 30px;
  line-height: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.talk-card-subtitle {
  margin-top: 35px;
  text-align: justify;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 16px;
  color: #7f8287;
  line-height: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.talk-button {
  background-color: #55198b;
  color: #ffffff;
  width: 90px;
  padding: 10px 18px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: block;
  margin-top: 20px;
  font-size: 14px;
  cursor: pointer; }

.talk-button:hover {
  background: #8c43ce; }

.card-footer-button-div {
  display: flex;
  justify-content: space-around; }

/* Media Query */
@media (max-width: 1380px) {
  .card-title {
    font-size: 25px; } }

@media (max-width: 768px) {
  .mask {
    -webkit-clip-path: none;
            clip-path: none;
    position: relative; }
  .talk-card-title {
    margin-top: 30px; }
  .talk-card-image {
    width: 100%; }
  .talk-button {
    background-color: #55198b;
    color: #ffffff;
    width: 90px;
    padding: 10px 18px;
    border-radius: 3px;
    text-align: center;
    text-decoration: none;
    display: block;
    margin-top: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s ease-in; }
  .talk-button:hover {
    background: #8c43ce; }
  .card-footer-button-div {
    display: flex;
    justify-content: space-around; }
  .square {
    width: 100%;
    height: auto;
    padding-bottom: 30px; } }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.podcast-header-title {
  font-size: 56px;
  font-weight: 400;
  line-height: 0px; }

.podcast {
  width: 600px; }

.podcast-main-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem 1rem;
  gap: 1rem 1rem; }

.subTitle {
  color: #868e96; }

@media (max-width: 768px) {
  .podcast-header-title {
    font-size: 30px;
    text-align: center; }
  .podcast-header-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center; }
  .podcast {
    width: 300px; }
  .podcast-main-div {
    text-align: center; } }

.education-heading {
  font-size: 56px;
  font-weight: 400; }

.education-section {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 20px 0px;
  margin: 0px auto;
  margin-top: 2rem; }

.education-section > * {
  flex: 1 1;
  margin-bottom: 30px; }

.education-card-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px; }

@media (max-width: 1380px) {
  .education-card-container {
    padding-top: 0px; }
  .education-heading {
    font-size: 40px; }
  .education-text-subtitle {
    font-size: 18px;
    text-align: center; } }

@media (max-width: 768px) {
  .education-card-container {
    padding-top: 0px; }
  .education-heading {
    font-size: 30px;
    text-align: center; }
  .education-text-subtitle {
    font-size: 16px; } }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.education-card {
  display: flex;
  flex-direction: row; }

.education-card:hover + .education-card-border {
  border-color: #a9a7f9;
  width: 100%;
  transition: width 0.6s ease-in-out; }

.education-card-border {
  height: 30px;
  width: 80%;
  border-top: 2px solid rgba(211, 211, 211, 0.397);
  margin-bottom: 20px;
  transition: width 0.6s ease-in-out; }

.education-head {
  display: flex;
  flex-direction: row; }

.education-text-school {
  margin: 0;
  font-size: 28px;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 27px; }

.education-roundedimg {
  object-fit: cover;
  margin-right: 2rem;
  width: 6rem;
  height: 6rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  border-radius: 50%; }

.education-card-right {
  max-width: 70%; }

.education-text-subHeader {
  color: #000000;
  font-weight: 700;
  font-size: 19px;
  margin: 0px;
  padding-top: 0.8rem;
  line-height: normal; }

.education-text-duration {
  color: #000000;
  font-size: 19px;
  margin: 0px;
  padding-top: 0.5rem; }

.education-text-desc {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.education-text-bullets > ul {
  margin-top: 0.5rem; }

/* Media Query */
@media (max-width: 1380px) {
  .education-card {
    flex-direction: column;
    padding-bottom: 10px; }
  .education-card-right {
    max-width: 90%; }
  .education-card-border {
    width: 90%; }
  .education-text-subHeader {
    padding-top: 0.5rem;
    font-size: 16px; }
  .education-text-school {
    font-size: 20px;
    height: 23px; }
  .education-text-duration {
    font-size: 16px; }
  .education-roundedimg {
    width: 4rem;
    height: 4rem;
    margin-bottom: 0.5rem;
    margin-right: 0px; } }

@media (max-width: 768px) {
  .education-card {
    text-align: center; }
  .education-card-right {
    max-width: 100%; }
  .education-card-border {
    width: 100%; }
  .education-roundedimg {
    margin-top: 10px;
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
    margin-right: 0; }
  .education-text-bullets > ul {
    max-width: 80%;
    margin: auto; } }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
#topButton {
  visibility: hidden;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #55198b;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 15px;
  font-size: 25px; }

#topButton:hover {
  background-color: #000;
  transition: all ease-in-out 0.2s; }

.centerContent {
  text-align: center;
  margin: auto;
  display: block;
  margin: 0 auto;
  padding: 1.5rem; }

.tw-main-div {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  width: auto;
  padding: 1.5rem; }

.twitter-component {
  width: auto;
  padding: 1.5rem; }

.timeline-Header-title {
  font-size: 56px;
  font-weight: 100 !important; }

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.contact-div-main {
  display: flex; }

.dark-mode a {
  color: #ffffff; }

.contact-div-main > * {
  flex: 1 1; }

.contact-title {
  font-size: 65px;
  font-weight: 400; }

.contact-detail,
.contact-detail-email {
  margin-top: 20px;
  font-size: 40px;
  font-weight: 400;
  color: #868e96;
  text-decoration: none; }

.contact-detail:hover,
.contact-detail-email:hover {
  color: #000000;
  text-shadow: 2px 1px 2px #b5b5b5;
  transition: all 0.3s; }

.contact-subtitle {
  text-transform: uppercase; }

.contact-text-div {
  margin-top: 1.5rem; }

.contact-margin-top {
  margin-top: 4rem; }

.contact-image-div > img {
  max-width: 100%;
  height: auto;
  margin-left: 1.5rem;
  margin-top: -4rem; }

/* Media Query */
@media (max-width: 1380px), (max-width: 768px) {
  .contact-title {
    font-size: 56px;
    font-weight: 400;
    text-align: center; }
  .contact-title {
    font-size: 40px;
    text-align: center; }
  .contact-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center; }
  .contact-text-div {
    text-align: center;
    margin-top: 1.5rem; }
  .contact-detail,
  .contact-detail-email {
    font-size: 20px; }
  .contact-image-div {
    display: none; } }

.splash-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.splash-animation-container {
  height: 50vh;
}

.splash-title-container {
  font-size: 2.5rem;
  text-decoration: none;
}
.splash-title {
  font-family: "Agustina Regular", cursive;
  font-weight: bold;
  font-feature-settings: "liga", "clig off";
  font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
  padding: 0 20px;
}

/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.dark-mode {
  background-color: #171c28;
  color: white;
  transition: "0.1s"; }

