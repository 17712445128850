/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */
.social-media-div {
  font-size: 2em; }

.icon-button {
  margin-bottom: 10px; }

.icon-button i {
  color: #ffffff;
  border-radius: 2.6rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1.3rem;
  height: 2.6rem;
  line-height: 2.6rem;
  margin: 0 5px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 2.6rem;
  margin-bottom: 10px;
  transition: 0.2s ease-in; }

.facebook i {
  background-color: #3b5998; }

.linkedin i {
  background-color: #0e76a8; }

.github i {
  background-color: #333; }

.gitlab i {
  background-color: #fca326; }

.google i {
  background-color: #ea4335; }

.twitter i {
  background-color: #1da1f2; }

.medium i {
  background-color: #000; }

.stack-overflow i {
  background-color: #f48024; }

.kaggle i {
  background-color: #20beff; }

.phone i {
  background-color: #1243e0; }

.instagram i {
  background-color: #c13584; }

/* Hover Transition */
.twitter i:hover,
.google i:hover,
.gitlab i:hover,
.github i:hover,
.linkedin i:hover,
.facebook i:hover,
.instagram i:hover,
.stack-overflow i:hover,
.kaggle i:hover,
.medium i:hover {
  background-color: #000000; }

/* Media Query */
@media (max-width: 768px) {
  .social-media-div {
    text-align: center; } }
